import api from "@/api/Api";
class Request {
    constructor() { }
    post(urn, data) {
        api.events();
        return api.httpRequest.post(urn, data);
    }
    patch(urn, data) {
        api.events();
        return api.httpRequest.patch(urn, data);
    }
    delete(urn) {
        api.events();
        return api.httpRequest.patch(urn);
    }
    uploadFile(urn, data) {
        api.events();
        return api.httpRequest.post(urn, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
    get(urn, paramsRequest = {}) {
        api.events();
        return api.httpRequest.get(urn, { params: paramsRequest });
    }
}
export default new Request();
