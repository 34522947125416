import request from "@/api/Request";
import { ApiResponse } from "@/api/Response";
class DefaultRequest {
    constructor() { }
    get(url) {
        return new Promise(function (resolve, reject) {
            request
                .get(url)
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data.data));
                }
                else if (res.data.type == "wait") {
                    resolve(new ApiResponse("wait", res.data.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    delete(url) {
        return new Promise(function (resolve, reject) {
            request
                .delete(url)
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data.data));
                }
                else if (res.data.type == "wait") {
                    resolve(new ApiResponse("wait", res.data.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    init() {
        return new Promise(function (resolve, reject) {
            request
                .get("auth/me")
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    const response = new ApiResponse("success", res.data.data);
                }
                else {
                    reject(new ApiResponse("error", "", res.data.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    post(url, params, returnError = false) {
        return new Promise(function (resolve, reject) {
            request
                .post(url, params)
                .then((res) => {
                res.data.type =
                    typeof res.data.type === "undefined" ? "error" : res.data.type;
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success" || res.data.type == "confirm") {
                    resolve(new ApiResponse(res.data.type ?? "error", res.data.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.message ?? res.data.data));
                }
            })
                .catch((err) => {
                if (returnError) {
                    reject(new ApiResponse("error", "", err));
                }
            });
        });
    }
    uploadFile(url, paramsForm) {
        return new Promise(function (resolve, reject) {
            request
                .uploadFile(url, paramsForm)
                .then((res) => {
                if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.message));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
}
export default new DefaultRequest();
